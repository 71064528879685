<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="NOL Card Gap" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="NOL Card Gap Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/NOLCardGapDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/NOLCardGapDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light pb-4">
              The DoD has used the NOL card gap test as one of the criteria to
              distinguish whether an explosive material is a 1.1 or a 1.3.
              Instead of placing the booster right next to the sample, an
              attenuator is placed between the shock source and the material
              such that the sample is subjected to a reduced shock event. The
              attenuation level is varied to determine the 50% probability
              between detonation and no detonation. For in-process materials, a
              substance with explosive characteristics is considered a mass
              explosive (Division 1.1) unless shown to have a detonation
              sensitivity value of less than 70 cards.
            </p>

            <p class="p-light pb-4">
              This test is similar to the UN gap test except that a shorter tube
              of material is used and a varied attenuator is placed between the
              test material and the pentolite booster. The attenuators provide a
              reduced shock source. The criteria for a “+” is that a clean hole
              is punctured through the witness plate. Typically 12 trials are
              required to determine the 50% “+/-” level. The test determines if
              a material will propagate an attenuated detonation.
            </p>

            <p class="p-light pb-4">
              The shock pressure imparted to the sample using 160 grams of
              Pentolite (Lot 718) is recorded in MIL-STD-1751A Method 1041: NOL
              Card Gap Test Calibration Data as shown below in the table where
              0.01 inch is equivalent to 1 card.
            </p>
            <a
              :href="
                require('../../assets/IPSeries/NOLCardGapShockPressureTable-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/NOLCardGapShockPressureTable-min.png"
              />
            </a>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Initiating device</td>
                      <td>Repeatably initiate the booster</td>
                      <td></td>
                      <td>#8 cap or equivalent</td>
                    </tr>
                    <tr>
                      <td>Booster</td>
                      <td>
                        Provide a strong, repeatable, stable shock front to the
                        top of the sample
                      </td>
                      <td></td>
                      <td>
                        160 grams of RDX/wax (95/5) or PETN/TNT (50/50), 50 mm
                        diameter, ~50mm length
                      </td>
                    </tr>
                    <tr>
                      <td>Gap</td>
                      <td>Attenuate the shock pressure</td>
                      <td>
                        <small
                          >MIL-STD-1751A Method 1041: PMMA was initially chosen
                          as the gap material because</small
                        >
                        <p></p>
                        <ul>
                          <li>
                            It is stable to changes in temperature and humidity,
                          </li>
                          <li>
                            It matches the impedance of solid non-porous test
                            materials better than most other commonly used
                            attenuators, and
                          </li>
                          <li>
                            It is much more convenient to use than molded wax.
                          </li>
                        </ul>
                        <p>
                          <small
                            >Additional advantage of PMMA, particularly over
                            metal gaps, is that PMMA forms no damaging
                            fragments. The latter situation complicates the
                            estimation of the shock wave transmitted from the
                            gap to the test material which has already been
                            pre-compressed by the elastic wave. The disadvantage
                            of PMMA is its viscoelastic behavior and the
                            resultant uncertainty of its relaxation times.
                            Hence, in the low pressure range there is still some
                            uncertainty about whether a pressure lower than the
                            equilibrium value should be used.
                          </small>
                        </p>
                      </td>
                      <td>
                        Standard attenuator sizes are 0.010, 0.050, 0.100,
                        0.250, 0.500, 0.750,<br />
                        1.00, and 1.50 inches
                      </td>
                    </tr>
                    <tr>
                      <td>Confining medium</td>
                      <td>
                        Provide confinement, increasing the susceptibility of
                        the substance to detonation.
                      </td>
                      <td></td>
                      <td>
                        Cold-drawn, seamless, mild steel (SAE 1015 – 1040) tube:
                        47.6mm (1.875-in) OD, 5.6mm wall (36.5mm (1.44-in) ID),
                        139.7mm (5.5-in) long.<br />
                        NOTE: Same pipe as UN Gap (legacy) or TB 700-2 (2012)
                      </td>
                    </tr>
                    <tr>
                      <td>Substance density</td>
                      <td>
                        Determine whether the substance in its in-process form
                        is sensitive to the test stimulus
                      </td>
                      <td></td>
                      <td>
                        Substance to be tested as near as possible to the
                        in-process density
                      </td>
                    </tr>
                    <tr>
                      <td>Spacer</td>
                      <td>Separate the steel tube from the witness plate</td>
                      <td>
                        <small
                          >MIL-STD-1751A Method 1041: The small stand-off
                          (0.063-inch gap) between the acceptor and the witness
                          plate was introduced to prevent the witness plate from
                          shattering and thereby facilitate interpretation of
                          test results.</small
                        >
                      </td>
                      <td>1.6 ± 0.2mm (1/16-in) thick</td>
                    </tr>
                    <tr>
                      <td>Witness plate specifications</td>
                      <td>Evidence of reaction type</td>
                      <td>
                        <small
                          >MIL-STD-1751A Method 1041: Some laboratories prefer
                          to use witness plates with larger length and width,
                          e.g., 9″ square, to reduce the likelihood of
                          shattering the witness plate. This is an acceptable
                          variation.</small
                        >
                      </td>
                      <td>
                        Mild steel (e.g., SAE 1015 – 1026) witness plate 6-inch
                        square by 0.375-inch-thick steel
                      </td>
                    </tr>
                    <tr>
                      <td>Offset</td>
                      <td>Offset the witness plate from the ground</td>
                      <td></td>
                      <td>
                        2 x 4 in wood blocks at the edge of the witness plate
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      “Neat” hole punched through the witness plate: [IP
                      Division 1.1 if sensitive to detonation when shock
                      pressure is attenuated by greater than 70 cards (70 kbar)]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent class="pt-8" title="Setup Example" size="small" />
            <a
              :href="require('../../assets/IPSeries/setupnolcardgap-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/setupnolcardgap-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Witness Plate Not Holed"
              size="small"
            />
            <a
              :href="require('../../assets/IPSeries/nogonolcardgap-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/nogonolcardgap-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Witness Plate Not Holed"
              size="small"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166130627?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166130627"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Neatly Holed Witness Plate"
              size="small"
            />
            <a
              :href="require('../../assets/IPSeries/GoExamplenolgo-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/GoExamplenolgo-min.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
